.clientAccount-container {
    /* border: 1px solid red; */
    padding: 3rem 4rem;
    position: relative;
    padding-top: 0rem;
    background-image: url('../../../Assets/Images/Background.jpg');
    background-repeat: no-repeat;
    background-size: 100% 18rem;
}

.heading_rightSide_findMycar>:first-child {
    font-size: 1rem;
    font-weight: 500;
}

.info_block_left_desc {
    font-size: .8rem;
    font-weight: 400;
    color: var(--text_color_one);
}

.inputGrop-profile {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.inputGrop-profile>p {
    font-size: .7rem;
    color: var(--text_color_one);
}

.inputGropKyc-custom {
    border: 1px solid var(--border_color_two);
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    align-items: center;
    border-radius: 5px;
}

.inputGropKyc-custom>p {
    font-size: .8rem;
    color: var(--text_color_two);
    pointer-events: none;
}

.customLabel-kyc {
    width: 35%;
    height: 100%;
}

.btn-kyc- {
    height: 100%;
    border: none;
    text-transform: capitalize;
    outline: none;
    color: #000;
    display: flex;
    cursor: pointer;
    font-size: .9rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--border_color_two);
    border-radius: 5px;
}

.btn_pForm {
    width: 11rem;
    height: 3.2rem;
    /* margin-top: 1rem; */
}

.btnContainer_pform {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

/* .btnContainer_pform>* {
    flex: 1 1;
} */

.clientAccount-parent {
    /* border: 1px solid blue; */
    /* margin-top: 2rem; */
    padding-top: 2rem;
}

.content-container-profile {
    /* border: 1px solid purple; */
    /* height: 30rem; */
    border-radius: 15px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
    display: flex;
    gap: 2rem;
}

.left-content-container-profile {
    /* border: 1px solid red; */
    width: 19%;
    border-radius: 15px;
    background: var(--gradient-seven);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 1.5rem;
}

.breadCrumb-container-profile-section {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: sticky;
    top: 1rem;
}

.breadCrumb-profilesection {
    /* border: 1px solid red; */
    display: flex;
    padding: .7rem 1rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    transition: all .1s ease-in;
    border-radius: 5px;
}

.breadCrumb-profilesection.breadCrumb-profilesection-active {
    box-shadow: var(--boxShadowTwo);
    background-color: #fff;
}

.icon-profile-breadcrumb {
    /* border: 1px solid red; */
    width: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.breadCrumb-profilesection>p {
    font-size: .9rem;
}

.right-content-container-profile {
    /* border: 1px solid red; */
    width: 81%;
    padding: 2.2rem 0rem;
    display: flex;
    padding-right: 2rem !important;
    flex-direction: column;
    gap: 1.5rem;
}

.heading_rightSide_findMycar-policyCard {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid red; */
    flex-direction: row;
}

.btn-polsection {
    display: flex;
    /* width: 127px; */
    padding: 11.5px 20px;
    justify-content: center;
    align-items: center;
}

#inputFileMerge {
    opacity: 0;
    position: absolute;
    width: 150px;
    height: 52px;
    cursor: pointer;
}

.downloadBtn-PolicyList {
    background-color: #7367F0;
    width: 50px;
    height: 30px;
    border: none;
    outline: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.uploadBtn-PolicyList {
    background-color: #fff;
    width: 50px;
    height: 30px;
    border: 1px solid var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.css-y8ay40-MuiTableCell-root {
    padding: 10px 10px !important;
}

.css-1ex1afd-MuiTableCell-root {
    padding: 10px 10px !important;
}