.checkBox-container {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkBox-container>img {
    /* padding-bottom: 2px; */
    cursor: pointer;
}