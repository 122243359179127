.carDetails-container {
    /* border: 1px solid red; */
    padding: 3rem 4rem;
    position: relative;
    background: var(--gradient-seven);
    min-height: 100dvh;
}

.breadCrumb-parent {
    position: absolute;
    top: .5rem;
}

.carDetails-parent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-container-cardetails {
    padding: 2rem;
    padding-top: 1rem;
    width: 60%;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: var(--boxShadow);
}

.prod-name-cardetails {
    font-size: 1rem;
}

.form-content-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.form-side-vehicledetails {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}



.inp-container-vehicledetails {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 60%;
}

.label-inputs {
    font-size: .8rem;
}

.radio-vehicledetails {
    width: auto;
}

.radio-container-cardetails {
    display: flex;
    gap: 1rem;
}

.btngrp-container {
    display: flex;
    gap: 1.5rem;
}

.btn-vehicledetails {
    width: 150px;
    height: 52px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
}

.disabled-inputField {
    /* border: 1px solid red; */
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed ;
    /* cursor: ; */
}

.disabled-inputField:hover {
    cursor: not-allowed;
}