.btn-myAccount {
    display: flex;
    align-items: center;
    background: var(--primary);
    padding: .5rem;
    gap: .5rem;
    width: 156px;
    height: 38px;
    color: #fff;
    font-size: .9rem;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.img-circle {
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.input-container-form {
    display: flex;
    /* border: 1px solid red; */
    gap: 2rem;
}

.input-container-form>*{
    flex: 1 1;
}