.ProfileCard-container {
    /* border: 1px solid red; */
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.input_group_pform {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
    position: relative;
}

.input_group_pform>* {
    flex: 1 1;
}

.inputGrop-profile {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.inputGrop-profile>p {
    font-size: .7rem;
    color: var(--text_color_one);
}

.pic-square {
    border: 1px solid var(--border_color_two);
    background-color: var(--background-colorFive);
    width: 63px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    flex-shrink: 0;
}

.delete-container {
    display: flex;
    margin-top: 1rem;
    gap: .5rem;
}

.delete-container>p {
    color: var(--text_color_one);
}

/* responsiveness */

@media only screen and (max-width: 610px) {

    .left-content-container-profile {
        display: none;
    }

    .right-content-container-profile {
        width: 100%;
        padding: 1rem 1rem;
        padding-right: 1rem !important;
        gap: 1rem;
    }

    .clientAccount-container {
        padding: 1rem;
    }

    .clientAccount-parent {
        padding-top: 0rem;
    }

    .ProfileCard-container {
        gap: 1rem;
    }

    .NoPoliciesFound-tabs {
        padding: 1rem !important;
    }

}

@media only screen and (max-width: 376px) {
    .NoPoliciesFound-tabs-btns {
        flex-direction: column;
    }

    .btn_clientAcc {
        width: 100%;
    }
}