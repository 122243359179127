/* Tooltip.css */
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-content {
    visibility: hidden;
    width: 220px;
    background-color: #F3F7FF;
    color: var(--primary);
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 125%;
    /* Adjust this value to move the tooltip vertically */
    left: 50%;
    margin-left: -100px;
    /* Use half of the width (200px) to center it */
    opacity: 0;
    transition: opacity 0.3s;
    border: 1px solid var(--primary);
}

.tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
}

.tooltip-arrow {
    position: absolute;
    bottom: 100%;
    /* Position the arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent var(--primary) transparent;
    /* Arrow pointing upwards */
}