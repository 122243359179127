.irdai-block-container {
    background-color: var(--background-color);
    height: 100%;
    width: 100%;
}

.irdai_container {
    /* border: 1px solid; */
    padding: 35px 35px;
    background-color: var(--background-color);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.irdai_container_head {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    /* border: 1px solid red; */
}

.irdai_container_head>img {
    width: 5rem;
    height: 2rem;
}

.irdai_small_text {
    color: #000;
}

.getRegNo_highlight_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.getRegNo_highlight_container_part {
    /* border: 1px solid rgb(101, 101, 2); */
    display: flex;
    justify-content: space-between;
}

.numbers_getregno {
    font-size: 2.5rem;
    font-weight: 600;
}

.numbers_getregno_blue {
    color: var(--primary);
}

.getRegNo_form_head {
    color: black;
    font-weight: 400;
    font-size: 1.5rem;
}

.getRegNo_form_head>span {
    font-weight: bold;
}