.btn-container-modal {
    /* border: 1px solid red; */
    display: flex;
    /* justify-content: space-around; */
    gap: 1rem;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
}

.msg-edirect>span {
    color: var(--greenText);
    font-weight: 600;
}

.msg-edirect-succes-primary {
    color: var(--primary);
    font-weight: 600;
}

.content-block-redirect-payment {
    height: 24rem;
    width: 25rem;
}