.nav-container {
    /* border: 1px solid red; */
    padding: .8rem 4rem;
    box-shadow: var(--boxShadowFour);
}

.nav-parent {
    /* border: 1px solid blue; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bmi-logo {
    width: 12rem;
    cursor: pointer;
}

.wallet-btn {
    border: 2px solid var(--primary);
    display: flex;
    align-items: center;
    padding: .5rem;
    gap: .5rem;
    min-width: 156px;
    height: 38px;
    color: #000;
    font-size: .9rem;
    border-radius: 7px;
    cursor: pointer;
    font-weight: bold;
    background-color: #fff;
    position: relative;
    padding-left: 2rem;
    justify-content: space-between;
}

.wallet-div {
    position: absolute;
    left: -1rem;
    /* border: 1px solid red; */
    height: fit-content;
    display: flex;
    background-color: #fff;
    /* margin-right: 1rem; */
}

.btn-container-navbar {
    display: flex;
    gap: 1rem;
}