.back-breadcrumb {
    display: flex;
    width: fit-content;
    gap: .5rem;
    cursor: pointer;
}

.back-breadcrumb>p {
    font-size: .9rem;
}

.back-breadcrumb>img {
    width: 1rem;
}