* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    font-family: 'Poppins', sans-serif;
    /* outline: 1px solid red; */
}

body {
    font-size: .8rem;
    /* line-height: 1rem; */
    font-weight: 400;
}

:root {
    --gradient-one: linear-gradient(134deg, #DFE9FF 0%, #FEE6E7 100%);
    --gradient-two: radial-gradient(55.52% 55.52% at 54.83% 100.00%, #FEE6E7 0%, rgba(255, 255, 255, 0.00) 100%);
    --gradient-three: linear-gradient(133deg, #DFE9FF 0%, rgba(255, 255, 255, 0.00) 100%);
    --gradient-four: linear-gradient(136deg, rgba(177, 201, 255, 0.80) 0%, rgba(254, 230, 231, 0.80) 100%);
    --gradient-five: linear-gradient(137deg, #E3E4FF 0%, #FFF 100%);
    --gradient-six: linear-gradient(118deg, rgba(177, 201, 255, 0.80) -3.56%, rgba(254, 230, 231, 0.80) 103.92%);
    --gradient-seven: linear-gradient(100deg, #DFE9FF -0.55%, #FEE6E7 106.25%);
    --primary: #5086FF;
    --background-color: #F1F5FF;
    --background-colorTwo: #E2EBFF;
    --background-colorThree: #B1C9FF;
    --background-colorFour: #D9D9D9;
    --background-colorFive: #EBF1FF;
    --background-colorSix: #EDF3FF;
    --maxWidth: 1300px;
    --text_color_one: #60646A;
    --text_color_two: #B2B2B2;
    --text_color_three: #1A1B1D;
    --text_color_four: #4D4D4D;
    --greenText: #23D32A;
    --redTxt: #FF464D;
    --border_color: #B2B2B2;
    --border_color_two: #B1C9FF;
    --border_color_three: #DFE9FF;
    --boxShadow: 0px 0px 50px 0px rgba(80, 134, 255, 0.20);
    --boxShadowTwo: 0px 0px 10px 0px rgba(80, 134, 255, 0.20);
    --boxShadowThree: 0px 0px 24px 0px rgba(80, 134, 255, 0.20);
    --boxShadowFour: 0px 5px 15px -7px rgba(0, 0, 0, 0.16);
    --boxShadowFive: 0px 0px 15px 0px rgba(80, 134, 255, 0.20);
    ;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.maxWidth {
    max-width: var(--maxWidth);
    margin: auto;
}

.button {
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    display: flex;
    border: none;
    /* padding: 1rem 0rem; */
    outline: none;
    transition: all .1s ease;
    cursor: pointer !important;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.button:hover {
    background-color: #2C5ECD;
}

.secondry_btn {
    border: 1px solid var(--primary);
    cursor: pointer;
    color: var(--primary);
    background-color: #fff;
}

.secondry_btn:hover {
    background-color: #DFE9FF;
}

.small_text {
    font-size: .85rem;
    font-weight: 400;
    cursor: pointer;
    color: var(--text_color_one);
}

.inputs-form {
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    height: 100%;
    width: 100%;
    padding: 1rem;
    outline: none;
}

.inputs-form :focus {
    border: none;
    outline: none;
}

.inputs-form::placeholder {
    color: var(--text_color_two);
}

.underline_text {
    text-decoration: underline;
}

.edit_btn {
    border: 1px solid var(--primary);
    border-radius: 5px;
    background: #FFF;
    width: 79px;
    height: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: .3rem;
    outline: none;
    color: var(--primary);
}

.center-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* SCROLLBAR */

::-webkit-scrollbar {
    width: .5em;
}

::-webkit-scrollbar-track {
    position: relative;
    right: 5px;
    border-radius: 20px;
}

::-webkit-scrollbar-thumb {
    background: var(--border_color_three);
    border-radius: 20px;
    outline: none;
}

a {
    text-decoration: none;
}


/* modal css */

.ReactModal__Overlay {
    position: fixed !important;
    inset: 0 !important;
    background: var(--gradient-four) !important;
    backdrop-filter: blur(2px) !important;
    z-index: 9 !important;
}

.ReactModal__Content {
    border: none !important;
    box-shadow: var(--boxShadow) !important;
    border-radius: 15px !important;
}

.ReactModal__Content {
    padding: 0 !important;
}

.modal_container {
    /* border: 1px solid red; */
    display: flex;
    height: 33rem;
}

.leftSide_modal {
    /* width: 40%; */
    background: var(--gradient-five);
    padding: 2rem 2rem;
    border-radius: 0px 15px 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.rightSide_modal {
    /* width: 60%; */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 3rem;
    /* border: 1px solid red; */
}

.modal_title {
    font-size: 1.1rem;
    font-weight: 500;
}

.content_left_modal_head {
    font-size: .85rem;
    width: 13rem;
    font-weight: 500;
}

.content_left_modal_desc {
    /* border: 1px solid; */
    width: 10rem;
    font-size: 0.8rem;
    color: var(--text_color_one);
}

.title_rightSide_modal {
    color: var(--text_color_three);
    font-weight: 500;
    /* border: 1px solid green; */
    display: flex;
    color: var(--text_color_three);
    flex-direction: column;
    gap: .3rem;
    font-size: 1.1rem;
}

.title_rightSide_modal>span {
    font-size: .75rem;
    font-weight: 400;
    color: var(--text_color_one);
}

.modal_selection_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-right: 6rem !important;
    padding: 1rem;
    border: 1px solid var(--border_color_two);
    border-radius: 10px;
}

.modal_selection_question {
    font-size: .85rem;
    font-weight: 500;
}

.small_textModal {
    font-size: .75rem;
}

.modal_options_container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.modal_options_container>button {
    border: 1px solid var(--border_color_two);
    width: 5.3rem;
    height: 1.8rem;
    background-color: #fff;
    font-size: .8rem;
    border-radius: 5px;
    cursor: pointer;
}

.closeImg_modal {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: .9rem;
    cursor: pointer;
}