/* Hide the default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.container {
    display: flex;
    width: fit-content;
}


/* Create a custom checkbox */
.checkmark {
    position: relative;
    border: 1px solid #DFE9FF;
    /*   box-shadow: rgb(255, 84, 0) 0px 0px 0px 2px; */
    /*   background-color: rgba(16, 16, 16, 0.5); */
    background-color: #F1F5FF;
    height: 22px;
    width: 22px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
}

.container input:checked~.checkmark {
    border: 1px solid #5086FF;
    background-color: #F1F5FF;
    height: 22px;
    width: 22px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 3px;
    height: 9px;
    border: solid #5086FF;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
    transition: all 500ms ease-in-out;
}

/* vp */

.viewPlan_checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.viewPlan_checkbox {
    display: flex;
    /* gap: 10px; */
    width: fit-content;
}

.viewPlan_checkbox input:checked~.checkmark {
    border: 1px solid #5086FF;
    background-color: #F1F5FF;
    height: 22px;
    width: 22px;
    margin-right: 10px;
    flex-shrink: 0;
    margin-top: -1px;
    transition: all 0.2s ease 0s;
    cursor: pointer;
    transform-origin: 0px 10px;
    border-radius: 4px;
    margin: -1px 10px 0px 0px;
    padding: 0px;
    box-sizing: border-box;
}

.viewPlan_checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.viewPlan_checkbox .checkmark:after {
    left: 0.58em;
    top: 0.28em;
    width: 0.35em;
    height: 0.65em;
    border: solid #5086FF;
    border-width: 0 0.15em 0.15em 0;
    transform: rotate(45deg);
    transition: all 500ms ease-in-out;
}