:root {
    --primary: #5086FF;
    --gray: #60646A;
    --grayTwo: #76767B;
    --black: #1A1B1D;
    --bgBlue: #F1F5FF;
    --bgBlueThree: #EEF1FF;
    --bgBlueTwo: #F6F9FF;
    --blue: #000096;
    --borderColor: #B1C9FF;
    --borderColorTwo: #ECF2FF;
    --gradient: linear-gradient(93.83deg, #DEE8FF 0.77%, #F6F9FF 103.72%);
}

.pdf-container {
    width: 595px;
    height: 842px;
    margin: auto;
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 1rem;
}

.pdfContent {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.pdfContent-invoiceDetails {
    border: 1px solid var(--borderColor);
    margin-top: 3rem;
    position: relative;
    /* border: 1px solid var(--borderColor); */
    border-radius: 10px;
    /* height: 10rem; */
}

.block-header {
    position: absolute;
    top: -2rem;
    height: 2rem;
    left: 13px;
    padding: 0px 10px;
    border-radius: 5px 5px 0px 0px;
    background-color: var(--blue);
    color: #fff;
    border: none;
    outline: none;
    /* font-weight: 600; */
}



.service-container>p:first-child {
    color: var(--blue);
    /* font-weight: 600; */
    padding-left: 10px;
}

.service-container>p:last-child {
    color: var(--gray);
    font-size: .5rem;
    margin-top: 5px;
    text-align: center;
}

.service-container-block {
    display: flex;
    gap: 5px;
    margin-top: 15px;
}


.service-icon-circle {
    /* border: 1px solid blue; */
    width: 50px;
    height: 50px;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.service-icon-circle_highlighted {
    /* background: var(--gradientColor); */
    width: 50px;
    height: 50px;
    display: flex;
    background-color: red;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.service-square {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 78px;
}

.service-square>p {
    font-size: .5rem;
    text-align: center;
    margin-top: 5px;
    /* font-weight: 500; */
}

.pdfContent-invoiceDetails-header {
    display: flex;
    padding: 10px 13px;
    /* background-color: red; */
    gap: 1.3rem;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid var(--borderColor);
}

.pdfContent-invoiceDetails-header>* {
    width: 50%;
    /* border: 1px solid blue; */
}

.invoiceDetails-header-block {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.head {
    color: var(--gray);
    min-width: fit-content;
}

.value {
    color: var(--black);
    /* font-weight: 500; */
}

.detail-block {
    padding: 10px 13px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.detail-block-row>* {
    width: 122px;
}

body {
    /* background-color: silver; */
}

.summary-block {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.summary-block>p:first-child {
    color: var(--blue);
    /* font-weight: 600; */
}

.summary-block>p {
    color: var(--gray);
    /* font-weight: 500; */
}

.summary-block-bottom>p{
    color: var(--black);
    /* font-weight: 700; */
}

.summary-block-bottom-bold>p {
    color: var(--primary);
    /* font-weight: 700; */
}

.detail-block-container {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--borderColor);
    justify-content: space-between;
}

.detail-block-container>* {
    width: 122px;
}

.detail-block-container:last-child {
    border: none;
}

.detail-block-row {
    flex-direction: row;
    justify-content: space-between;
}

.invoiceDetails-header-block-width>p {
    padding-left: 1rem;
}

.detail-block-head {
    color: var(--blue);
    /* font-weight: 600; */
}

.detail-block-head-absolute {
    position: absolute;
    top: 13px;
}

.totalPremium {
    border: 1px solid var(--borderColorTwo);
    background-color: var(--bgBlue);
    width: fit-content;
    height: fit-content;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.padding-top-pdf {
    padding-top: 2.9rem;
    justify-content: flex-start;
}

.totalPremium>p:first-child {
    /* font-weight: 600; */
}

.totalPremium>p:last-child {
    /* font-weight: 600; */
    font-size: 1.2rem;
    color: var(--primary);
}

.detail-block-flexEnd {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* height: 100%; */
}

.detail-block-head {
    margin-bottom: .5rem;
}

.value {
    text-align: right;
}

.detail-block-blue {
    background-color: var(--bgBlue);
    border-radius: 0px 0px 10px 10px;
}

.head-blue {
    color: var(--primary);
    /* font-weight: 500; */
}

.blackText {
    color: var(--black);
}