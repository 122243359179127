.dataTable-container {
    /* border: 1px solid red; */
    /* width: 1005; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: flex-end; */
}

.tableBtn-container {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
}

.inputFile::file-selector-button {
    background-image: url("../../../Assets/Images/bike.svg");
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.heading-container>p {
    font-size: 1.2rem;
    color: var(--primary);
    font-weight: 600;
}

.css-xn82ks-MuiTable-root {
    border-radius: 5px !important;
}

.css-rorn0c-MuiTableContainer-root {
    box-shadow: 0px 0px 8px 0px rgba(168, 160, 245, 0.20) !important;
}

.css-y8ay40-MuiTableCell-root:first-child {
    border-radius: 5px 0px 0px 0px !important;
}

.css-y8ay40-MuiTableCell-root:last-child {
    border-radius: 0px 5px 0px 0px !important;
}