.dropDown_container {
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    width: 100%;
    border-radius: 5px 5px 5px 5px;
}

.label-dropdown {
    position: absolute;
    top: -10px;
    left: 5px;
    transform: scale(.8);
    /* transform: translate(0, -62px) scale(0.8); */
    background: #fff;
    color: var(--text_color_two);
    padding: 0px 5px;
}

.rmvLeft {
    left: 0px;
}

.dropdown-header {
    /* border: 1px solid red; */
    width: fit-content;
    padding: 0px 2px;
    background-color: #fff;
    color: var(--text_color_two);
    position: absolute;
    top: -.5rem;
    left: 1rem;
    font-size: .65rem;
    z-index: 1;
}

.dropDown_container:focus {
    border: 1px solid var(--primary) !important;
}

.NoBorderRadius {
    border-radius: 5px 5px 0px 0px;
}

.dropDownHead {
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    padding: 0rem 1rem;
    cursor: pointer;
}

.dropDownHead>p {
    color: var(--text_color_two);
    font-size: .8rem;
    /* font-weight: 500; */
}

.dropDownHead>img {
    width: 1rem;
}

.fullHeightDpOptions {
    height: 6.5rem;
    background-color: #fff;
    /* max-height: fit-content !important; */
}

.dropDownOptions {
    /* padding: .5rem 1rem; */
    display: flex;
    max-height: 190px;
    /* max-height: 230px; */
    background-color: #fff;
    position: absolute;
    overflow: auto;
    border-top: 0px !important;
    z-index: 2;
    width: 100%;
    display: none;
    border: 1px solid var(--border_color_two);
    border-radius: 0px 0px 5px 5px;
    flex-direction: column;
    /* gap: 1.1rem; */
}

.removeAbsolute {
    position: relative;
    border: 0;
    /* background-color: red !important; */
}

.dropDownOptions.showdropDownOptions {
    display: flex;
    background-color: #fff;
}

.policyDropDown {
    position: relative;
}

.policyDropDown {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.policyDropDownOptions {
    /* border: 1px solid red; */
    display: none;
    border-radius: 5px;
    border: 1px solid var(--border_color_two);
    margin-top: 10px;
    cursor: pointer;
    /* padding: 10px 10px; */
    background-color: #fff;
    z-index: 4;
    position: absolute;
    min-width: 10rem;
}


.addonDropDownOptions {
    /* border: 1px solid red; */
    padding-top: 5px;
    display: none;
    width: 16rem;
    left: -87%;
    border-radius: 5px;
    border: 1px solid var(--border_color_two);
    margin-top: 10px;
    cursor: pointer;
    /* padding: 10px 10px; */
    background-color: #fff;
    z-index: 2;
    position: absolute;
}

.addonDropDownOptions.showpolicyDropDownOptions {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.policyDropDownOptions.showpolicyDropDownOptions {
    display: flex;
    flex-direction: column;
    /* gap: 1rem; */
}

.policyDropDownHead {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    cursor: pointer;
    font-weight: 400;
    width: 133px;
}

.dropdownItems-text {
    border-radius: 5px;
    padding: .5rem 1rem;
    font-size: .85rem;
    background-color: #fff;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer !important;
}

.dropdownItems-text:hover {
    background-color: var(--secondry);
    /* color: #fff; */
}

.policyTypeInp-dp {
    color: var(--primary);
    background-color: #fff;
    /* border: 1px solid red; */
    outline: none;
    border: none;
    text-transform: capitalize !important;
    width: 7rem;
    cursor: pointer;
}

@media only screen and (max-width: 610px) {
    .dropdownItems-text {
        font-size: 1rem;
    }
}