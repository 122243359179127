.loader-btn {
    /* border: 1px solid blue; */
    height: 52px;
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    background-color: var(--primary);
}

.dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 9.4rem;

}

.dot {
    height: 10px;
    width: 10px;
    margin-right: 13px;
    border-radius: 10px;
    background-color: #ffffff;
    animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
    margin-right: 0;
}

.dot:nth-child(1) {
    animation-delay: -0.3s;
}

.dot:nth-child(2) {
    animation-delay: -0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.1s;
}

@keyframes pulse {
    0% {
        transform: scale(0.1);
        background-color: #ffffff;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.337);
    }

    50% {
        transform: scale(1.2);
        background-color: #ffffff;
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.3);
        background-color: #ffffff;
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.282);
    }
}