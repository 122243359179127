.productselection-container {
    /* border: 1px solid red; */
    padding: 2rem 4rem;
    background: var(--gradient-seven);
    padding-bottom: 9rem;
    min-height: 100dvh;
}

.productselection-parent {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

.vehicleType-parent {
    padding-top: 2rem;
}

.headingWithSpan {
    font-size: 1.2rem;
}

.headingWithSpan>span {
    font-weight: bold;
}

.productContainer {
    /* border: 1px solid green; */
    padding: 1rem 0rem;
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
}

.prod-block {
    /* border: 1px solid red; */
    background-color: #fff;
    width: 15rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 1rem;
    padding: 1rem;
    border-radius: 10px;
    justify-content: center;
    box-shadow: var(--boxShadow);
}

.prod-name {
    color: var(--primary);
    font-size: .95rem;
}

.prod-name>span {
    color: #000;
}

.prod-block-img {
    width: 3.5rem;
}

.icon-container-prod {
    width: fit-content;
    /* border: 1px solid red; */
    height: 3rem;
}