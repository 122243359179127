.editVehicleDetails-container {
    padding: 2rem 4rem;
    /* border: 1px solid red; */
    background: var(--gradient-seven);
    min-height: 100dvh;
}

.Input_Dropdown {
    border: 0;
    outline: 0;
}

.editVehicleDetails-parent {
    /* border: 1px solid blue; */
    padding: 2rem 2rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
}

.form-container-editVehicleDetails {
    /* border: 1px solid red; */
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 30rem;
}

.head-form-container {
    display: flex;
    justify-content: space-between;
}

.head-form-container>p:first-child {
    font-size: .85rem;
    font-weight: 500;
}

.imp-msg {
    font-size: .8rem;
    font-weight: 400;
    color: var(--redTxt);
}

.input-list-editVehicleDetails {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.input-list-editVehicleDetails-parts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.input-field-btn {
    /* border: 1px solid red; */
    position: relative;
}

.fetch-btn {
    /* border: 1px solid blue; */
    position: absolute;
    right: 0;
    top: 0;
    height: 52px;
    width: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: var(--primary);
    color: #fff;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.disable-fetch-btn {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed !important;
}