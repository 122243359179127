.mis_Conatiner{
    padding: 1rem 4rem 4rem 2rem;
 }
 .misTilte{
     margin-bottom: 2.5rem;
 }
 .misTilte>p{
     color: #1A1B1D;
 font-family: Poppins;
 font-size: 18px;
 font-style: normal;
 font-weight: 500;
 line-height: normal;
 }
 .messagetext{
    display: flex;
    justify-content: space-between;
  
 }
 .messagetext>p:nth-child(1){
     font-size: 16px;
     margin-top: 2rem;
     margin-bottom: 0.8rem;
 }
 .messagetext>p:nth-child(2){
    color: red;
    font-size: 10px;
    margin-top: 2.4rem;
    margin-bottom: 1rem;
 }
 .horz_line{
     height: 1px;
     background:#DFE9FF;
 }